import React, { useState, useRef } from 'react';
import './cry.css';

const CryptoExperienceSite = () => {
  const [currentWallpaper, setCurrentWallpaper] = useState(1);
  const [isPunched, setIsPunched] = useState(false);
  const [showContent, setShowContent] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [notification, setNotification] = useState(null);
  
  const sadMessages = [
    { text: "How can I suffer more...  😢", emoji: "😢" },
    { text: "I'm crying right now....😭", emoji: "😭" },
    { text: "Hit, slap more.... 😪", emoji: "😪" },
    { text: "Please kill me by shooting me, I can't stand it... 💔", emoji: "💔" },
    { text: "Each stroke burns me more and more...  😿", emoji: "😿" },
    { text: "Why are you so cruel... 💔", emoji: "💔" }
  ];

  const audioRefs = useRef([
    new Audio('./punchmemp3/1.mp3'),
    new Audio('./punchmemp3/2.mp3'),
    new Audio('./punchmemp3/3.mp3')
  ]);

  const showNotification = (message) => {
    setNotification(message);
    setTimeout(() => setNotification(null), 4000); // 
};

  const handlePunch = () => {
    setIsPunched(true);
    setShowContent(false);
    setCurrentWallpaper(prev => prev === 3 ? 1 : prev + 1);
    
    const randomAudio = audioRefs.current[Math.floor(Math.random() * audioRefs.current.length)];
    randomAudio.play();
    
    const randomMessage = sadMessages[Math.floor(Math.random() * sadMessages.length)];
    showNotification(randomMessage);
    
    setTimeout(() => setIsPunched(false), 500);
    setTimeout(() => setShowContent(true), 2000);
  };

  const images = [
    './media/image1.jpg',
    './media/image2.jpg',
    './media/image3.jpg',
    './media/image4.jpg',
    './media/image5.jpg'
  ];

  const socialLinks = [
    { name: 'Twitter', url: 'https://x.com/nopaperhands_xx', icon: '🐦' }
  ];

  return (
    <div className="site-container">
      {notification && (
        <div className="notification fade-in">
          <div className="notification-emoji">{notification.emoji}</div>
          <div className="notification-text">{notification.text}</div>
        </div>
      )}

<div 
  className={`bg-wrapper ${isPunched ? 'fade-transition' : ''}`}
  style={{
    backgroundImage: `url('./media/wallpaper${currentWallpaper}.jpg')`,
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',  // Merkeze hizalama
    width: '100%',
    height: '100%',
    backgroundSize: 'contain',  // 'cover' yerine 'contain' kullanıyoruz
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#000',    // Siyah arka plan
    zIndex: -1,
  }}
>
  <div className="overlay"></div>
</div>

      {selectedImage && (
        <div className="modal-overlay" onClick={() => setSelectedImage(null)}>
          <div className="modal-content">
            <img 
              src={selectedImage} 
              alt="Preview" 
              className="modal-image"
            />
            <button className="modal-close" onClick={() => setSelectedImage(null)}>
              ×
            </button>
          </div>
        </div>
      )}

      <div className="content-wrapper">
        <header className="site-header">
          <h1 className="main-title">My Crypto Journey</h1>
          <p className="subtitle">A Story of Lessons Learned in the Crypto Market</p>
          <button 
            className={`punch-button ${isPunched ? 'punched' : ''}`}
            onClick={handlePunch}
          >
            SLAP ME
          </button>
        </header>

        {showContent && (
          <>
            <div className="image-gallery fade-in">
              <h2 className="section-title">Gallery</h2>
              <div className="gallery-grid">
                {images.map((image, index) => (
                  <div 
                    key={index} 
                    className="gallery-item"
                    onClick={() => setSelectedImage(image)}
                  >
                    <img 
                      src={image} 
                      alt={`Gallery ${index + 1}`} 
                      className="gallery-image"
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="grid-container fade-in">
              <div className="content-card">
                <h2 className="section-title">Sad Story</h2>
                <p className="text-content">
                i have been in the market since bull 2016 i have made much money many times and each time i managed to bring the balance to zero.i have been in 50 stxs 32 sols 24 π and 7 runestone and many similar early mints this year . but i sold them all early and lost the incoming coins elsewhere. sols ath $280k, stxs ath $30k, π ath $780k. i want to create a new community and not repeat my mistakes and move towards getting rich with everyone else who has nopaperhands token . No paperhands fuck paperhands
                </p>
                <img 
                  src="./media/image1.jpg" 
                  alt="Trading Journey" 
                  className="feature-image"
                />
              </div>

              <div className="content-card">
                <h2 className="section-title">Trading Timeline</h2>
                <div className="video-container">
                  {[1, 2, 3].map((num) => (
                    <video 
                      key={num}
                      controls 
                      className="video-player"
                    >
                      <source src={`./media/video${num}.mp4`} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ))}
                </div>
              </div>
            </div>

            <div className="content-card full-width fade-in">
  <h2 className="section-title">New Token Project</h2>
  <p className="text-content">
    Learning from past experiences, I'm launching a new token project 
    with a focus on long-term sustainability and community value.
  </p>
  <div className="info-grid">
    <div className="info-card">
      <h3 className="info-title">Project Details</h3>
      <p>PumpFunda Token will be launched soon. Details will be shared immediately upon launch.</p>
      <p className="commitment-text">I commit not to withdraw before reaching 10 million market cap.</p>
    </div>
    <div className="info-card">
      <h3 className="info-title">Community</h3>
      <p>Join our Twitter community to stay updated about the launch and project developments.</p>
    </div>
  </div>

  <div className="social-section">
    <h3 className="social-title">Connect With Us</h3>
    <div className="social-grid">
      {socialLinks.map((link, index) => (
        <a
          key={index}
          href={link.url}
          target="_blank"
          rel="noopener noreferrer"
          className="social-link"
        >
          <span className="social-icon">{link.icon}</span>
          <span className="social-name">{link.name}</span>
                    </a>
                  ))}
                </div>
                
                <div className="newsletter-box">
                  <h3 className="newsletter-title">Stay Updated</h3>
                  <div className="newsletter-form">
                    <input 
                      type="email" 
                      placeholder="Enter your email" 
                      className="newsletter-input"
                    />
                    <button className="newsletter-button">Subscribe</button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CryptoExperienceSite;